table tr th {
  background: #201925 !important;
  color: white !important;
}

table tr th,
table tr td {
  border: 1px solid rgba(125, 125, 125, 0.25) !important;
}

.ant-select-selector {
  border-radius: 8px !important;
}

.ant-select-item-option:hover,
.ant-select-item-option:focus,
.ant-select-item-option-active {
  background-color: #c7c7c7 !important;
  color: #000 !important;
  font-weight: bold !important;
}
